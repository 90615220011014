import React from "react";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";

import { LoadingOverlay } from "./styled";

const Loading = ({ loading }) =>
  loading ? (
    <LoadingOverlay>
      <ClipLoader color="#ffffff" loading={loading} size={60} />
    </LoadingOverlay>
  ) : (
    null
  );

Loading.propTypes = {
  loading: PropTypes.bool,
};

Loading.defaultProps = {
  loading: false,
};

export default Loading;
